import { CheckCircleFilled, ExclamationCircleFilled, SyncOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { startCase } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { PortalPaymentFragment } from '@willow/graphql-iso/src/portal';
import { NamedMemo, paymentAmountFormat, paymentDateFormat } from '@willow/shared-web';
import { CompanyId, LoanId, PortalPaymentDueStatus, PortalPaymentDueSubStatus } from '@willow/types-iso';

import { CancelPayment } from './CancelPayment';

import './PaymentDetails.scss';

interface Props {
  companyId: CompanyId;
  loanId: LoanId;
  payments: PortalPaymentFragment[];
  displayMoreButton?: boolean;
  status: PortalPaymentDueStatus;
  subStatus?: PortalPaymentDueSubStatus;
  onAdditionalPayment?: () => void;
  refetch: (poll?: boolean) => void;
}

export const PaymentDetails = NamedMemo<Props>(
  'PaymentDetails',
  ({ companyId, loanId, payments, displayMoreButton = false, onAdditionalPayment, refetch }) => {
    const [displayMorePayment, setDisplayMorePayment] = useState(false);

    const makeAdditionalPayment = useCallback(() => {
      setDisplayMorePayment(false);
      onAdditionalPayment && onAdditionalPayment();
    }, [onAdditionalPayment]);

    useEffect(() => {
      setDisplayMorePayment(displayMoreButton);
    }, [payments, displayMoreButton]);

    return (
      <section
        className={classnames(['payment-details', 'payment-details__container'], {
          'payment-details__more': displayMorePayment,
        })}
      >
        <h2>Payment Details</h2>
        <h3>Amount Processing</h3>

        <ul>
          {payments.map((payment) => {
            let { id, total, paymentDate, status, canCancel } = payment || {};
            const totalFormatted = paymentAmountFormat(total);
            const dateFormatted = paymentDateFormat(paymentDate);
            const dateFormattedMobile = paymentDateFormat(paymentDate, true);

            let formattedStatus = startCase(status.toLowerCase());
            if (status === 'PENDING') {
              formattedStatus = 'Processing';
            }

            return (
              <li key={`payment-details-${id}`} className="payment-details__container">
                <div className="payment-details__item">
                  <div className="payment-details__item__info">
                    <div className="d-flex gap-5 align-items-center">
                      <div className="payment-details__item__info__total">{totalFormatted}</div>
                    </div>

                    <div className="payment-details__item__info__date tablet">Submitted on {dateFormatted}</div>
                    <div className="payment-details__item__info__date mobile">{dateFormattedMobile}</div>
                  </div>

                  <div className="payment-details__item__processing d-flex align-items-center">
                    <div className="payment-details__item__processing__icon">
                      {status === 'PENDING' && <SyncOutlined />}
                      {status === 'PROCESSED' && <CheckCircleFilled />}
                      {status === 'FAILED' && <ExclamationCircleFilled />}
                      {status === 'CANCELLED' && <ExclamationCircleFilled />}
                    </div>
                    {formattedStatus}
                  </div>
                </div>

                {canCancel && (
                  <div className="w-100 mt-2">
                    <CancelPayment companyId={companyId} loanId={loanId} payment={payment} refetch={refetch} />
                  </div>
                )}
              </li>
            );
          })}
        </ul>

        {displayMorePayment && (
          <div className="payment-details__addition">
            <button
              className="payment-details__addition__btn"
              data-ghost="portal-loan--make-a-payment-button"
              onClick={makeAdditionalPayment}
            >
              Make Another Payment
            </button>
          </div>
        )}
      </section>
    );
  },
);
