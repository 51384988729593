import { ReactNode, useState } from 'react';
import ReactSelect, { components, OptionProps, Props as SelectProps, ValueContainerProps } from 'react-select';

import { WILLOW_SELECT_STYLES, WILLOW_SELECT_THEME } from './styles';

type OptionType = { label: ReactNode | JSX.Element; value: string | number };
function Option<TOptions extends OptionType, TIsMulti extends boolean>({
  isSelected,
  ...props
}: OptionProps<TOptions, TIsMulti>) {
  return (
    <div>
      <components.Option {...{ isSelected, ...props }}>
        <div className="d-flex gap-2">
          <input type="checkbox" className="form-check-input" checked={isSelected} onChange={() => null} />
          <label
            className="u-color-bark4"
            style={{ textOverflow: 'ellipsis', maxWidth: '85%', overflow: 'hidden', whiteSpace: 'nowrap' }}
          >
            {props.data.label}
          </label>
        </div>
      </components.Option>
    </div>
  );
}

function ValueContainer<TOptions extends OptionType, TIsMulti extends boolean>({
  children,
  ...props
}: ValueContainerProps<TOptions, TIsMulti>) {
  if (!props.hasValue) return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  // @ts-ignore
  const otherChildren = children ? children[1] : undefined;
  const selectedOptions = props.hasValue ? props.getValue() : [];
  const visibleOptions = selectedOptions.slice(0, 1);
  const remainingCount = selectedOptions.length - visibleOptions.length;

  return (
    <components.ValueContainer {...props}>
      <span
        style={{
          textOverflow: 'ellipsis',
          maxWidth: 'calc(100% - 10px)', // overflow space for ellipses + remove all icon
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {visibleOptions.map((op) => op.label)}
        {remainingCount > 0 && <> +{remainingCount}</>}
      </span>
      {otherChildren}
    </components.ValueContainer>
  );
}

export function WillowMultiSelect<TOptions extends OptionType>({ onChange, ...props }: SelectProps<TOptions, true>) {
  const [inputValue, setInputValue] = useState('');

  return (
    <div className="multiselect-wrapper">
      <ReactSelect
        isMulti
        components={{ Option, ValueContainer }}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        // @ts-ignore
        onChange={onChange}
        styles={WILLOW_SELECT_STYLES}
        theme={WILLOW_SELECT_THEME}
        onInputChange={(inputString, meta) => {
          if (meta.action === 'input-change') {
            setInputValue(inputString);
          }
          // Now check with options were found and select them, making sure
          // to merge with existing values
          if (meta.action === 'menu-close') {
            const valuesToFind = inputValue.trim().split(/\s+|,/);
            const foundOptions = Array.isArray(props.options)
              ? props.options?.filter((option) => valuesToFind.includes(option.label))
              : [];
            const newValues = Array.isArray(props.value) ? [...props.value, ...foundOptions] : foundOptions;
            onChange?.(newValues, { action: 'select-option', option: undefined });
          }
        }}
        {...props}
      />
    </div>
  );
}
