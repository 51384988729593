import * as z from 'zod';

import { LoanDocument } from '../loan/Document';

/* Document Template Types */
export const PreActiveWelcomeLetterDocument = z.literal('preActiveWelcome');
export const MonthlyStatementDocument = z.literal('monthlyStatement');
export const LenderUploadDocument = z.literal('lenderUpload');
export const BorrowerUploadDocument = z.literal('borrowerUpload');
export const GoodbyeLetterDocument = z.literal('goodbye');
export const MortgageeLetterDocument = z.literal('mortgagee');
export const PayoffStatementDocument = z.literal('payoff');
export const PaidInFullStatementDocument = z.literal('paidInFull');
export const PartialPayoffConfirmationDocument = z.literal('partialPayoffConfirmation');
export const Form1098Document = z.literal('form1098');
export const EscrowAnalysisDocument = z.literal('escrowAnalysis');
export const DefermentWorkoutPlanDocument = z.literal('defermentWorkoutPlan');
export const RepaymentWorkoutPlanDocument = z.literal('repaymentWorkoutPlan');
export const ForbearanceWorkoutPlanDocument = z.literal('forbearanceWorkoutPlan');
export const DelinquentInterestAppliedDocument = z.literal('delinquentInterestApplied');
export const PrincipalModificationAppliedDocument = z.literal('principalModificationApplied');
export const EmailCopyDocument = z.literal('emailCopy');

export const LoanDocumentType = z.union([
  PreActiveWelcomeLetterDocument,
  MonthlyStatementDocument,
  GoodbyeLetterDocument,
  LenderUploadDocument,
  BorrowerUploadDocument,
  MortgageeLetterDocument,
  PayoffStatementDocument,
  PartialPayoffConfirmationDocument,
  PaidInFullStatementDocument,
  Form1098Document,
  EscrowAnalysisDocument,
  DefermentWorkoutPlanDocument,
  RepaymentWorkoutPlanDocument,
  ForbearanceWorkoutPlanDocument,
  DelinquentInterestAppliedDocument,
  PrincipalModificationAppliedDocument,
  EmailCopyDocument,
]);
export type LoanDocumentType = z.infer<typeof LoanDocumentType>;

/** Documents that should be mailed (either automatically or via a mail task) */
export const ImmediateMailDocumentType = z.union([
  PreActiveWelcomeLetterDocument,
  Form1098Document,
  EscrowAnalysisDocument,
]);
export type ImmediateMailDocumentType = z.infer<typeof ImmediateMailDocumentType>;

export const PendingMailDocumentType = z.union([GoodbyeLetterDocument, MortgageeLetterDocument]);
export type PendingMailDocumentType = z.infer<typeof PendingMailDocumentType>;

export const MailDocumentType = z.union([ImmediateMailDocumentType, PendingMailDocumentType]);
export type MailDocumentType = z.infer<typeof MailDocumentType>;

export type MailDocument = Extract<LoanDocument, { type: MailDocumentType }>;
