import { WarningOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { CancelPaymentDocument, PortalPaymentFragment } from '@willow/graphql-iso/src/portal';
import { Button, Modal } from '@willow/shared-web';
import { CompanyId, LoanId } from '@willow/types-iso';

interface Props {
  companyId: CompanyId;
  loanId: LoanId;
  payment: PortalPaymentFragment;
  refetch: (poll?: boolean) => void;
}

export const CancelPayment = ({ companyId, loanId, payment, refetch }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [cancelPayment, { loading }] = useMutation(CancelPaymentDocument);

  const handleCancelPayment = async () => {
    try {
      await cancelPayment({
        variables: {
          companyId,
          loanId,
          paymentId: payment.id,
        },
      });
      toast.success('Payment cancellation submitted. Please allow a few moments for processing to complete.');
      setShowModal(false);
      refetch(true);
    } catch {
      toast.error('Something went wrong cancelling the payment. Please refresh the page and try again.');
    }
  };

  return (
    <>
      <Button
        size="sm"
        variant="tertiary"
        className="u-color-red1 hover:u-color-red2 text-decoration-none"
        onClick={() => setShowModal(true)}
      >
        Cancel payment
      </Button>

      <Modal
        showModal={showModal}
        showLoader={loading}
        onClose={() => setShowModal(false)}
        header={
          <h2>
            <WarningOutlined className="me-2 u-color-red1" /> Cancel Payment?
          </h2>
        }
        footer={
          <div className="mt-3 d-flex justify-content-end gap-2">
            <Button variant="secondary" size="sm" onClick={() => setShowModal(false)}>
              Do Not Cancel Payment
            </Button>
            <Button variant="primary" size="sm" onClick={handleCancelPayment}>
              Yes, Cancel Payment
            </Button>
          </div>
        }
      >
        <p>
          Are you sure you want to cancel this payment? This action cannot be reversed and any amount owed will need to
          be resubmitted.
        </p>
      </Modal>
    </>
  );
};
