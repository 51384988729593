import { z } from 'zod';

import { PayoffId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GeneratePartialPayoffConfirmationSideEffectContext = BaseLoanIdSideEffect.extend({
  payoffId: zodBrandedUuid<PayoffId>(),
});
export const GeneratePartialPayoffConfirmationSideEffect = GeneratePartialPayoffConfirmationSideEffectContext.extend({
  type: z.literal('generatePartialPayoffConfirmation'),
});
export type GeneratePartialPayoffConfirmationSideEffect = z.infer<typeof GeneratePartialPayoffConfirmationSideEffect>;
