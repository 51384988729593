import currency from 'currency.js';
import { round } from 'lodash';
import { DateTime } from 'luxon';

import { PaymentMethod } from '@willow/graphql-iso/src/app';
import { LoanPaymentStatus, LoanReportingPaymentStatus, PaymentStatus } from '@willow/types-iso';

import { assertNever } from './assertNever';

export const paymentMethodFormat = (method: PaymentMethod | undefined): string => {
  switch (method) {
    case 'DWOLLA':
      return 'ACH';
    case 'AUTHORIZED_ACH':
      return 'Authorized ACH';
    case 'CHECK':
      return 'Check';
    case 'WIRE':
      return 'Wire';
    case 'PHONE':
      return 'Phone';
    case 'RESERVE':
      return 'Reserve';
    case 'PRINCIPAL_PREPAYMENT':
      return 'Principal Prepayment';
    default:
      return '-';
  }
};

export const NEGATIVE_PARENTHESES_PATTERN = {
  negativePattern: `(!#)`,
};
// Documentation for currency options can be found here: https://currency.js.org/
export function paymentAmountFormat(amount: number, options?: currency.Options): string;
export function paymentAmountFormat(amount: number | undefined, options?: currency.Options): string | undefined;
export function paymentAmountFormat(amount: number | undefined, options?: currency.Options): string | undefined {
  if (!amount && amount !== 0) return undefined;

  return currency(amount, options).format();
}

export const disbursementAmountFormat = (
  amount: number | undefined,
  options?: currency.Options,
): string | undefined => {
  if (!amount && amount !== 0) return undefined;

  const absoluteAmount = Math.abs(amount);

  return amount <= 0
    ? paymentAmountFormat(absoluteAmount, options)
    : `(${paymentAmountFormat(absoluteAmount, options)})`;
};

export const reserveCreditAmountFormat = (
  amount: number | undefined | null,
  options?: currency.Options,
): string | undefined => {
  if (amount == null) return undefined;
  return amount > 0 ? `(${paymentAmountFormat(amount, options)})` : `${paymentAmountFormat(amount, options)}`;
};

export const roundTwoDecimalPoints = (amount: number | string): number => {
  return currency(amount, { precision: 2 }).value;
};

export function paymentDateFormat(date: string, isMobile?: boolean): string;
export function paymentDateFormat(date: string | undefined, isMobile?: boolean): string | undefined;
export function paymentDateFormat(date: string | undefined, isMobile: boolean = false): string | undefined {
  if (!date) return undefined;

  // Return shorter timedate for some mobile views
  const format = isMobile ? 'MM/dd/yyyy' : 'MMMM d, yyyy';
  return DateTime.fromJSDate(new Date(date)).toFormat(format);
}

const DEFAULT_DECIMAL_PLACES = 3;
export const decimalToPercentage = (decimalAmount: number, decimalPlaces: number = DEFAULT_DECIMAL_PLACES): string => {
  return `${round(decimalAmount * 100, decimalPlaces).toFixed(decimalPlaces)}%`;
};

export type FormattedPortalPaymentStatus = 'Cancelled' | 'Failed' | 'Processing' | 'Complete';
export const portalPaymentStatusFormat = (status: PaymentStatus): FormattedPortalPaymentStatus => {
  switch (status) {
    case 'CANCELLED':
      return 'Cancelled';
    case 'FAILED':
      return 'Failed';
    case 'PENDING':
      return 'Processing';
    case 'PROCESSED':
      return 'Complete';
  }
};

export type FormattedLoanPaymentStatus = 'Current' | 'Past Due';
export const loanPaymentStatusFormat = (status: LoanPaymentStatus): FormattedLoanPaymentStatus => {
  switch (status) {
    case 'CURRENT':
      return 'Current';
    case 'PASTDUE':
      return 'Past Due';
  }
};

export const paymentStatusLabel = (status: LoanReportingPaymentStatus) => {
  switch (status) {
    case 'CURRENT':
      return 'Current';
    case 'PASTDUE':
      return 'Past Due';
    case 'PASTDUE_30':
      return 'Past Due: 30';
    case 'PASTDUE_60':
      return 'Past Due: 60';
    case 'PASTDUE_90':
      return 'Past Due: 90';
    default:
      assertNever(status);
  }
};
