import { z } from 'zod';

import { DocumentId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const PhysicallyMailDocumentSideEffectContext = BaseLoanIdSideEffect.extend({
  documentId: zodBrandedUuid<DocumentId>(),
  auditLogComment: z.string(),
});
export const PhysicallyMailDocumentSideEffect = PhysicallyMailDocumentSideEffectContext.extend({
  type: z.literal('physicallyMailDocument'),
});
export type PhysicallyMailDocumentSideEffect = z.infer<typeof PhysicallyMailDocumentSideEffect>;
