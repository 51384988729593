import { z } from 'zod';

import { PrincipalModificationId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils';

export const PrincipalModification = z.object({
  id: zodBrandedUuid<PrincipalModificationId>(),
  effectiveDate: zodDateOrString,
  amount: z.number(),
  collectionType: z.enum(['waived', 'deferred']),
  isRecast: z.boolean(),
  isFederalGovernmentPlan: z.boolean(),
  status: z.enum(['active', 'cancelled']),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});

export type PrincipalModification = z.infer<typeof PrincipalModification>;

export const PrincipalModificationInput = PrincipalModification.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  status: true,
});

export type PrincipalModificationInput = z.infer<typeof PrincipalModificationInput>;
