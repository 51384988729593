import * as z from 'zod';

import { CompanyId, CompanyReportId, LoanId, LoanOwnerId, LosId, PoolId, UserId } from './BrandedIds';
import { CreditReportCycle } from './CreditReport';
import { LoanStatus } from './loan/LoanStatus';
import { IsoDateFilter } from './loanTransactionFilter/LoanTransactionFilter';
import { zodBrandedString } from './utils/Zod';

export interface CompanyReport {
  id: CompanyReportId;
  userId: UserId;
  companyId: CompanyId;
  title: string;
  location: string;
  loanCount: number;
  recordCount: number;
  type: CompanyReportType;
  recurring: boolean;
  fromDate?: Date;
  toDate?: Date;
  exactDate?: Date;
  creditReportCycle?: CreditReportCycle;
  createdAt: Date;
  updatedAt: Date;
  sftpSentStatus?: SftpSentStatus;
  sftpError?: string;
}

export enum SftpSentStatus {
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
  ERROR = 'ERROR',
}

export enum CompanyReportType {
  CASH_MANAGEMENT = 'CASH_MANAGEMENT',
  INVESTOR_REMITTANCE = 'INVESTOR_REMITTANCE',
  INVESTOR_REMITTANCE_NEW = 'INVESTOR_REMITTANCE_NEW',
  TRANSACTION_HISTORY = 'TRANSACTION_HISTORY',
  LOAN_NOTES = 'LOAN_NOTES',
  SERVICING_TRANSFER = 'SERVICING_TRANSFER',
  LOANS_BILLED = 'LOANS_BILLED',
  PAYMENT_SUMMARY = 'PAYMENT_SUMMARY',
  LOAN_PIPELINE = 'LOAN_PIPELINE',
  ESCROW_DISBURSEMENTS = 'ESCROW_DISBURSEMENTS',
  AUDIT_LOG = 'AUDIT_LOG',
  AUTHORIZED_ACH_BATCH = 'AUTHORIZED_ACH_BATCH',
  LOAN_PRODUCTIVITY = 'LOAN_PRODUCTIVITY',
  TRIAL_BALANCE = 'TRIAL_BALANCE',
  PRINCIPAL_REDUCTION = 'PRINCIPAL_REDUCTION',
  CREDIT_REPORT = 'CREDIT_REPORT',
  GOODBYE_LETTERS = 'GOODBYE_LETTERS',

  // Lower reports
  COMPILED_ACCRUALS_AND_PAYMENTS = 'COMPILED_ACCRUALS_AND_PAYMENTS',
  LOAN_TRANSFER_DOCUMENTS = 'LOAN_TRANSFER_DOCUMENTS',
  LOS_BALANCE_AND_TRANSACTIONS = 'LOS_BALANCE_AND_TRANSACTIONS',
  PAST_DUE_LOANS = 'PAST_DUE_LOANS',
  SHELLPOINT_HELOCS = 'SHELLPOINT_HELOCS',

  // Kensie Mae / Interline reports
  KENSIE_MAE_PAYMENT = 'KENSIE_MAE_PAYMENT',
  KENSIE_MAE_ESCROW_DISBURSEMENT = 'KENSIE_MAE_ESCROW_DISBURSEMENT',
  KENSIE_MAE_PRINCIPAL_PREPAYMENT = 'KENSIE_MAE_PRINCIPAL_PREPAYMENT',

  // Vontive reports
  ONE_80_INSURANCE = 'ONE_80_INSURANCE',

  // Wheda reports
  BORROWER_LOGIN = 'BORROWER_LOGIN',
  FHA_MONTHLY_PREMIUM = 'FHA_MONTHLY_PREMIUM',
  FHA_TS_264 = 'FHA_TS_264',
  FHA_TS_260 = 'FHA_TS_260',
  FHA_TS_266 = 'FHA_TS_266',
  CHECK_DISBURSEMENTS_FILE = 'CHECK_DISBURSEMENTS_FILE',
  DISBURSEMENT_DETAIL_FOR_MI_ESCROW = 'DISBURSEMENT_DETAIL_FOR_MI_ESCROW',
  DISBURSEMENT_RECONCILIATION = 'DISBURSEMENT_RECONCILIATION',
  MILAR_ABBREVIATED_AND_FULL = 'MILAR_ABBREVIATED_AND_FULL',
  ADR = 'ADR',
  FREDDIE_MAC_LLR = 'FREDDIE_MAC_LLR',
  FREDDIE_MAC_EDR = 'FREDDIE_MAC_EDR',
  FREDDIE_MAE_SDC = 'FREDDIE_MAE_SDC',
  FANNIE_MAE_CRS_DRAFT_REQUEST = 'FANNIE_MAE_CRS_DRAFT_REQUEST',
  FANNIE_MAE_LAR_96 = 'FANNIE_MAE_LAR_96',
  FANNIE_MAE_LAR_89 = 'FANNIE_MAE_LAR_89',
  FANNIE_MAE_LAR_32 = 'FANNIE_MAE_LAR_32',
  FANNIE_MAE_LAR_81 = 'FANNIE_MAE_LAR_81',
  FANNIE_MAE_LAR_83 = 'FANNIE_MAE_LAR_83',
  GINNIE_RFS = 'GINNIE_RFS',
  GINNIE_PDS = 'GINNIE_PDS',
  P_AND_I_ADVANCES = 'P_AND_I_ADVANCES',
  P_AND_I_INVESTOR_RECEIVABLE = 'P_AND_I_INVESTOR_RECEIVABLE',
  P_AND_I_RECONCILIATION = 'P_AND_I_RECONCILIATION',
  T_AND_I_RECONCILIATION = 'T_AND_I_RECONCILIATION',
  MORTGAGE_INSURANCE_CANCELLATION = 'MORTGAGE_INSURANCE_CANCELLATION',
  BORROWER_CONTACT = 'BORROWER_CONTACT',
  DELINQUENCY_AGING = 'DELINQUENCY_AGING',
  BANKRUPTCY_LOAN_STATUS = 'BANKRUPTCY_LOAN_STATUS',
  LOSS_MITIGATION_STATUS = 'LOSS_MITIGATION_STATUS',
  LOAN_MODIFICATION_SUCCESS_RATE = 'LOAN_MODIFICATION_SUCCESS_RATE',
  FORECLOSURE_STATUS = 'FORECLOSURE_STATUS',
}

export const DateFilterField = z.enum(['transferEffectiveDate', 'transferSoldDate', 'paymentProcessedDate']);

export type DateFilterField = z.infer<typeof DateFilterField>;

export const CompanyReportFilters = z
  .object({
    losIds: z.array(z.custom<LosId>()),
    poolIds: z.array(zodBrandedString<PoolId>()),
    ownerIds: z.array(zodBrandedString<LoanOwnerId>()),
    servicerNames: z.array(z.string()).optional(),
    userIds: z.array(zodBrandedString<UserId>()).optional(),
    fullIds: z.array(z.string()),
    capitalPartnerIds: z.array(z.string()),
    otherId1s: z.array(z.string()),
    previousServicerIds: z.array(z.string()),
    loanStatus: LoanStatus.optional(),
    dateFilterField: DateFilterField.optional(),
    reportingDaysDelta: z.number().int().gte(-7).lte(7).default(0),
    pastDue: z
      .object({ minDays: z.number().positive().optional(), maxDays: z.number().positive().optional() })
      .optional(),
    creditReportCycle: CreditReportCycle.optional(),
  })
  .merge(IsoDateFilter);

export type CompanyReportFilters = z.infer<typeof CompanyReportFilters>;

export type CreateReportResponse = {
  reportFile: Buffer;
  meta: {
    recordCount: number;
    loanCount: number;
    loanIds: LoanId[];
    minDate: Date;
    maxDate: Date;
  };
};
