import { z } from 'zod';

import { Address } from '../Address';
import { CompanyWireSettingsId, LoanOwnerId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';

export const CompanyWireSettings = z.object({
  id: zodBrandedUuid<CompanyWireSettingsId>(),
  isDefault: z.boolean(),
  bankName: z.string(),
  bankRoutingNumber: z.string(),
  accountName: z.string(),
  accountNumber: z.string(),
  bankAddress: Address,
  instructions: z.string().optional(),
  ownerIds: z.array(zodBrandedUuid<LoanOwnerId>()),
});
export type CompanyWireSettings = z.infer<typeof CompanyWireSettings>;
