import * as z from 'zod';

import { DocumentId, DrawId, PaymentDueId, PayoffId, WorkoutPlanId } from './BrandedIds';
import { MonthlyStatementDocument } from './documents/Templates';
import { BorrowerDocumentAccess } from './loan/DocumentAccess';
import { WorkoutPlanType } from './loan/WorkoutPlan';
import { zodBrandedUuid, zodDateOrString } from './utils/Zod';

export const PaymentDueStatus = z.object({
  amountRemaining: z.number(),
  amountRemainingAfterCredit: z.number().optional(),
  fulfilled: z.boolean(),
  fulfilledAt: zodDateOrString.optional(),
  isLate: z.boolean(),
  workoutPlanType: WorkoutPlanType.optional(),
});
export type PaymentDueStatus = z.infer<typeof PaymentDueStatus>;

const PaymentInputWithSeparatePnI = z.object({
  principal: z.number(),
  interest: z.number(),
  escrow: z.number(),
  reserveCredit: z.number().optional(),
  paymentDate: zodDateOrString,
  escrowShortage: z.number().optional(),
});
const PaymentInputWithCombinedPnI = z.object({
  principalAndInterest: z.number(),
  escrow: z.number(),
  reserveCredit: z.number().optional(),
  paymentDate: zodDateOrString,
  escrowShortage: z.number().optional(),
});
export const PaymentInput = z.union([PaymentInputWithSeparatePnI, PaymentInputWithCombinedPnI]);
export type PaymentInput = z.infer<typeof PaymentInput>;

const BaseInterestAttribution = z.object({
  startOfPeriodPrincipal: z.number(), // principal the calculation is based on
  interest: z.number(),
  days: z.number(),
  dailyInterestRate: z.number(),
  periodInterestRate: z.number().optional(), // used for statement display
  startOfPeriod: zodDateOrString.optional(),
  endOfPeriod: zodDateOrString.optional(),
  isWaived: z.boolean().optional(),
  isDeferred: z.boolean().optional(),
});

export const StandardInterestAttribution = BaseInterestAttribution.extend({
  type: z.literal('standardInterest'),
});
export type StandardInterestAttribution = z.infer<typeof StandardInterestAttribution>;

export const DrawInterestAttribution = BaseInterestAttribution.extend({
  type: z.literal('drawInterest'),
  drawId: zodBrandedUuid<DrawId>(),
});
export type DrawInterestAttribution = z.infer<typeof DrawInterestAttribution>;

export const PartialPayoffInterestAttribution = BaseInterestAttribution.extend({
  type: z.literal('partialPayoffInterest'),
  payoffId: zodBrandedUuid<PayoffId>(),
});
export type PartialPayoffInterestAttribution = z.infer<typeof PartialPayoffInterestAttribution>;

export const DelinquentInterestAttribution = BaseInterestAttribution.extend({
  type: z.literal('delinquentInterest'),
});
export type DelinquentInterestAttribution = z.infer<typeof DelinquentInterestAttribution>;

export const InterestAttribution = z.union([
  StandardInterestAttribution,
  DrawInterestAttribution,
  DelinquentInterestAttribution,
  PartialPayoffInterestAttribution,
]);
export type InterestAttribution = z.infer<typeof InterestAttribution>;

export const isStandardInterestAttribution = (ia: InterestAttribution): ia is StandardInterestAttribution =>
  ia.type === 'standardInterest';
export const isDrawInterestAttribution = (ia: InterestAttribution): ia is DrawInterestAttribution =>
  ia.type === 'drawInterest';
export const isDelinquentInterestAttribution = (ia: InterestAttribution): ia is DelinquentInterestAttribution =>
  ia.type === 'delinquentInterest';
export const isPartialPayoffInterestAttribution = (ia: InterestAttribution): ia is PartialPayoffInterestAttribution =>
  ia.type === 'partialPayoffInterest';

// Note: migrating to match documents
export const MonthlyStatement = z.object({
  id: zodBrandedUuid<DocumentId>(),
  type: MonthlyStatementDocument,
  url: z.string(),
  version: z.number(),
  comment: z.string().optional(),
  createdAt: zodDateOrString,
  borrowerAccess: BorrowerDocumentAccess,
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
  noteflowDocumentId: z.string().optional(),
});
export type MonthlyStatement = z.infer<typeof MonthlyStatement>;

export const Repayment = z.object({
  principal: z.number(),
  interest: z.number(),
  escrow: z.number(),
  total: z.number(),
  workoutPlanId: zodBrandedUuid<WorkoutPlanId>(),
});

export type Repayment = z.infer<typeof Repayment>;

export const PaymentDue = z
  .object({
    id: zodBrandedUuid<PaymentDueId>(),
    total: z.number(),
    statementDate: zodDateOrString,
    status: PaymentDueStatus,
    wentPastGrace: z.boolean(),
    calculator: z.string(),
    version: z.number(),
    statements: z.array(MonthlyStatement).optional(),
    attribution: z.array(InterestAttribution).optional(),
    interestRate: z.number().optional(),
    buydownInterestRate: z.number().optional(),
    createdAt: zodDateOrString,
    orphanedDrawsOnly: z.boolean().optional(),
    adjustedPaymentDate: zodDateOrString.optional(),
    repayment: Repayment.optional(),
  })
  .merge(PaymentInputWithSeparatePnI);
export type PaymentDue = z.infer<typeof PaymentDue>;

export type PortalPaymentDueStatus = 'due' | 'notDue' | 'submitted';
export const PortalPaymentDueSubStatus = z.object({
  isPastDue: z.boolean(),
  paymentFail: z.boolean(),
});
export type PortalPaymentDueSubStatus = z.infer<typeof PortalPaymentDueSubStatus>;
