import { z } from 'zod';

import { BorrowerId, PrincipalModificationId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GeneratePrincipalModificationAppliedSideEffectContext = BaseLoanIdSideEffect.extend({
  borrowerIds: z.array(zodBrandedUuid<BorrowerId>()),
  principalModificationId: zodBrandedUuid<PrincipalModificationId>(),
});

export const GeneratePrincipalModificationAppliedSideEffect =
  GeneratePrincipalModificationAppliedSideEffectContext.extend({
    type: z.literal('generatePrincipalModificationApplied'),
  });
export type GeneratePrincipalModificationAppliedSideEffect = z.infer<
  typeof GeneratePrincipalModificationAppliedSideEffect
>;
