/*
    Make a payment (amount + payment method)
    1. Select Amount
      a. Default to total amount due
      b. Select other amount (ie Under or Over-paying workflows)
    2. Payment Method
      a. Default to first available bank account (this will come from our bankend)
      b. Dwolla iframe to connect bank accounts
          – This is shown if a) user has no banks available OR b) they click button to add additional account


    Workflow options for this form
    1. Standard case: total amount due
    2. Underpayment
    3. Past Due
    4. Payment Failed
    5. Overpayment // Already paid, but user clicks "Make Payment" button on "Nothing Due" screen


    In addition to be displayed when a payment is due, this form may also be displayed for other status'
    if the user clicks on the "Make another payment" button. In this instance, only "Other amount" would display
    along with a message indicating that this is an additional payment
*/

import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import classnames from 'classnames';
import { FormEvent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Scroll from 'react-scroll';
import { toast } from 'react-toastify';

import {
  CompanyFragment,
  DwollaBank,
  InitiatePaymentDocument,
  InitiatePaymentMutationVariables,
  LoanBillSettingsType,
  PortalChargeFragment,
  PortalLoanSnapshot,
  PortalPaymentDueFragment,
  PortalPaymentFragment,
} from '@willow/graphql-iso/src/portal';
import { add, multiply, subtract } from '@willow/shared-iso';
import {
  ControlledCurrencyInput,
  getNextPaymentDueDate,
  Loader,
  paymentAmountFormat,
  paymentDateFormat,
} from '@willow/shared-web';
import { Form, OverlayTrigger, Tooltip } from '@willow/shared-web/bootstrap';
import { EscrowPaymentHistoryItemId, LoanId, LoanStatus, PortalPaymentDueSubStatus } from '@willow/types-iso';

import { PortalSelectedLoan } from '../../App';
import { Alert } from '../alert/Alert';
import { DwollaLimitModal } from '../dwolla-limit-modal/DwollaLimitModal';
import { PaymentMethod } from '../payment-method/PaymentMethod';
import { PaymentFormAlerts } from './alerts/PaymentFormAlerts';
import { AutopaySettingForm } from './autopay-setting/AutopaySettingForm';
import { ConfirmPaymentModal } from './confirm-payment-modal/ConfirmPaymentModal';
import { PaymentFormShape, TPaymentFormShape } from './form';
import { OverPaymentModal } from './over-payment-modal/OverPaymentModal';
import { UnderPaymentModal } from './under-payment-modal/UnderPaymentModal';
import { getPaymentFormAmountDueDisplay, paymentFormAmountDuesForDisplay } from './util';

import './PaymentForm.scss';

const SCROLLER = Scroll.scroller;

export interface PaymentFormProps {
  loanId: LoanId;
  loanStatus: LoanStatus;
  company: CompanyFragment;
  totalAmountDue: number;
  shortageAmount: number;
  escrowShortageAnalysisId: EscrowPaymentHistoryItemId | undefined;
  chargesAmountDue: number; // TODO subtract out from min payment amount / underpayment
  paymentsDue: PortalPaymentDueFragment[];
  processingPayments: PortalPaymentFragment[];
  chargesDue: PortalChargeFragment[];
  subStatus?: PortalPaymentDueSubStatus;
  isAdditionalPayment?: boolean; // used for views where the payment status is not "due"
  hasOutstandingBalance?: boolean;
  nextStatementDate?: string;
  autopaySettings?: PortalLoanSnapshot['autopaySettings'];
  hasInterestReserve: boolean;
  hasPayoffStatement: boolean;
  exactPaymentOnly: boolean;
  delinquentInterestRate: number;
  currentBillSettingsType: LoanBillSettingsType;
  activeWorkoutPlan: PortalSelectedLoan['currentSnapshot']['activeWorkoutPlan'] | undefined;
  onPaymentSubmission?: (variables: InitiatePaymentMutationVariables) => void; // notify parent of payment made
  refetch?: (poll?: boolean) => void; // refetch after successful submission
}

export type AdditionalPayment = {
  principal: number;
  escrow: number;
  suspense: number;
};

export const PaymentForm = (props: PaymentFormProps) => {
  const {
    loanId,
    loanStatus,
    company,
    totalAmountDue,
    shortageAmount,
    escrowShortageAnalysisId,
    chargesAmountDue,
    paymentsDue,
    processingPayments,
    chargesDue,
    subStatus,
    isAdditionalPayment,
    hasOutstandingBalance,
    nextStatementDate,
    autopaySettings,
    hasInterestReserve,
    hasPayoffStatement,
    exactPaymentOnly,
    delinquentInterestRate,
    currentBillSettingsType,
    activeWorkoutPlan,
    onPaymentSubmission,
    refetch,
  } = props;
  const [initiatePayment] = useMutation(InitiatePaymentDocument);

  const { control, reset, watch, setValue } = useForm<TPaymentFormShape>({
    resolver: zodResolver(PaymentFormShape),
    defaultValues: {
      otherAmount: 0,
      paymentIntention: 'total',
    },
  });

  // For escrow analysis statements, default paymentAmount includes the 1/12th shortage
  // This is the remaining 11/12ths of the shortage (to make us whole)
  const additionalEscrowShortage = multiply(shortageAmount / 12, 11);

  // Track payment amount and method for form submission
  const [paymentBank, setPaymentBank] = useState<DwollaBank | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [activeModal, setActiveModal] = useState<
    'overPayment' | 'underPayment' | 'confirmPayment' | 'dwollaLimit' | undefined
  >(undefined);
  const [hasBankError, setHasBankError] = useState(false);

  const [showPaymentForm, setShowPaymentForm] = useState(!autopaySettings);

  const { isPastDue } = subStatus || {};

  const paymentDate = getNextPaymentDueDate(paymentsDue);
  const amountDuesDisplay = getPaymentFormAmountDueDisplay(paymentsDue, chargesDue);
  const amountDuesForDisplay = paymentFormAmountDuesForDisplay(amountDuesDisplay, false);
  const amountDuesForShortageDisplay = paymentFormAmountDuesForDisplay(amountDuesDisplay, true);

  const onBankUpdate = useCallback((bank: DwollaBank | undefined) => {
    if (bank) {
      setHasBankError(false);
    }
    setPaymentBank(bank);
  }, []);

  const calculatePaymentAmount = () => {
    switch (paymentIntention) {
      case 'total':
        return totalAmountDue;
      case 'totalWithShortage':
        return add(totalAmountDue, additionalEscrowShortage);
      case 'other':
        return Number(otherAmount);
    }
  };

  const otherAmount = watch('otherAmount');
  const paymentIntention = watch('paymentIntention');
  const paymentAmount = calculatePaymentAmount();
  const minAmountDue = subtract(totalAmountDue, chargesAmountDue); // just the statement due amount. Borrower can pay amountDue - feeDue and the loan will be considered up to date
  const autopayEnabledByServicer = Boolean(autopaySettings && autopaySettings.type === 'usio');

  const submitPayment = async (additionalPayment?: AdditionalPayment) => {
    try {
      if (!paymentBank || !paymentAmount) {
        throw new Error('Bank or payment amount are not properly set.');
      }

      // On successful submission, the parent page loan hook will be notified and handle necessary view updates
      let variables: InitiatePaymentMutationVariables = {
        borrowerBankUrl: paymentBank.links.self,
        amount: paymentAmount,
        loanId,
        companyId: company.id,
      };

      if (paymentIntention === 'totalWithShortage' && escrowShortageAnalysisId) {
        variables = {
          ...variables,
          amount: paymentAmount,
          escrowShortageAnalysisId,
          additionalPrincipal: 0,
          additionalEscrow: additionalEscrowShortage,
          additionalSuspense: 0,
        };
      }

      if (additionalPayment) {
        // If additional payment (aka overpayment), update the payment amount to reflect the overpayment amounts)
        const overpaymentAmount = add(
          totalAmountDue,
          additionalPayment.principal,
          additionalPayment.escrow,
          additionalPayment.suspense,
        );

        variables = {
          ...variables,
          amount: overpaymentAmount,
          additionalPrincipal: additionalPayment.principal,
          additionalEscrow: additionalPayment.escrow,
          additionalSuspense: additionalPayment.suspense,
        };
      }

      await initiatePayment({
        variables,
      });

      reset();

      // Refetch Dwolla token after successful submission
      refetch && refetch();

      // Notify parent of successful payment submission (optional subscription field)
      if (onPaymentSubmission) {
        onPaymentSubmission(variables);
      }

      // Scroll to Payment header so user sees the update
      SCROLLER.scrollTo('payment-header', { offset: -150 });
    } catch (err: any) {
      // Determine error message to display
      if (err?.message?.indexOf('Invalid amount. The supplied amount is greater than your transaction limit.') >= 0) {
        setActiveModal('dwollaLimit');
      } else {
        toast.error('Whoops! Something went wrong with submitting your payment. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onModalSubmit = async (additionalPayment?: AdditionalPayment) => {
    // Set loader & close modals
    setIsSubmitting(true);
    setActiveModal(undefined);

    // Send through the payment
    await submitPayment(additionalPayment);
  };

  const onSubmit = async (e: FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    setIsSubmitting(true);
    setHasBankError(false);

    // Frontend validation (browser "required" should catch this already, but this is fallback)
    const missingData = !paymentAmount || !paymentBank;
    if (missingData) {
      if (!paymentBank) {
        setHasBankError(true);
        toast.error('Connected bank account is required');
      } else if (!paymentAmount) {
        toast.error('Enter a payment amount');
      }

      setIsSubmitting(false);
      return;
    }

    // Check if under or over payment
    if (paymentIntention === 'totalWithShortage' && escrowShortageAnalysisId) {
      setActiveModal('confirmPayment');
    } else if (minAmountDue > 0 && minAmountDue > paymentAmount) {
      // Underpayment
      setActiveModal('underPayment');
      setIsSubmitting(false);
    } else if ((totalAmountDue > 0 && totalAmountDue < paymentAmount) || isAdditionalPayment) {
      // Overpayment
      setActiveModal('overPayment');
      setIsSubmitting(false);
    } else {
      // Exact amount being submitted
      setActiveModal('confirmPayment');
    }
  };

  return (
    <>
      <PaymentFormAlerts
        isAdditionalPayment={isAdditionalPayment ?? false}
        company={company}
        totalAmountDue={totalAmountDue}
        paymentsDue={paymentsDue || []}
        processingPayments={processingPayments}
        subStatus={subStatus}
        hasOutstandingBalance={hasOutstandingBalance}
        autopaySettings={autopaySettings}
        hasInterestReserve={hasInterestReserve}
        hasPayoffStatement={hasPayoffStatement}
        autopayEnabledByServicer={autopayEnabledByServicer}
        delinquentInterestRate={delinquentInterestRate}
        currentBillSettingsType={currentBillSettingsType}
        activeWorkoutPlan={activeWorkoutPlan}
      />

      <section
        className={classnames(['payment-form', 'make-payment__container'], {
          'payment-form--busy': isSubmitting,
          'payment-form--addition': isAdditionalPayment,
        })}
      >
        <h1>Make a Payment</h1>

        {isAdditionalPayment && (
          <div className="payment-form__addition-alert">
            <Alert
              title={`Next statement available on ${nextStatementDate}`}
              subtitle="Only make a payment if instructed by your lender"
              level="ERROR"
            />
          </div>
        )}

        <form data-testid="payment-form" onSubmit={onSubmit}>
          <h2>
            Payment Method
            <OverlayTrigger
              placement="top"
              trigger={['hover', 'focus']}
              overlay={<Tooltip>Learn more about online payments at the FAQ page</Tooltip>}
            >
              <QuestionCircleOutlined />
            </OverlayTrigger>
          </h2>

          <div className={classnames(['payment-form__dwolla'], { 'payment-form__dwolla--error': hasBankError })}>
            <PaymentMethod companyName={company.name} autopaySettings={autopaySettings} onBankUpdate={onBankUpdate} />
          </div>

          <AutopaySettingForm
            autopaySettings={autopaySettings}
            paymentBank={paymentBank}
            loanStatus={loanStatus}
            loanId={loanId}
            totalAmountDue={totalAmountDue}
            isPastDue={isPastDue}
          />
          {showPaymentForm ? (
            <>
              <h2 data-testid="payment-form-amount-title">Amount</h2>
              <div className="make-payment__container0 payment-form__amount">
                {/* Only give "total" option if not making an additional payment */}
                {!isAdditionalPayment && (
                  <div
                    className={classnames(['payment-form__radio'], {
                      'u-bg-secondary': paymentIntention === 'total',
                    })}
                    onClick={() => {
                      setValue('paymentIntention', 'total');
                    }}
                    data-testid="payment-form-amount-total"
                  >
                    <Form.Check.Input
                      type="radio"
                      value="total"
                      className="checked:u-bg-willow"
                      onChange={() => setValue('paymentIntention', 'total')}
                      checked={paymentIntention === 'total'}
                    />
                    <Form.Check.Label>
                      <div className="payment-form__label_title desktop">Amount due {paymentDate}</div>
                      <div className="payment-form__label_title tablet">
                        Amount due
                        <br />
                        {paymentDateFormat(paymentDate, true)}
                      </div>

                      <div className="payment-form__total">{paymentAmountFormat(totalAmountDue) || '$0.00'}</div>
                    </Form.Check.Label>
                    {amountDuesForDisplay.length > 1 && (
                      <div className="payment-form__multiple-due">
                        <p>Amount due includes additional balance:</p>
                        <ul>
                          {amountDuesForDisplay.map(({ desktop, tablet }, index) => (
                            <li key={`overdue-item-${index}`}>
                              <span className="desktop">{desktop}</span>
                              <span className="tablet">{tablet}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
                {additionalEscrowShortage > 0 && (
                  <div
                    className={classnames(['payment-form__radio'], {
                      'u-bg-secondary': paymentIntention === 'totalWithShortage',
                    })}
                    onClick={() => {
                      setValue('paymentIntention', 'totalWithShortage');
                    }}
                    data-testid="payment-form-amount-totalWithShortage"
                  >
                    <Form.Check.Input
                      type="radio"
                      value="totalWithShortage"
                      className="checked:u-bg-willow"
                      checked={paymentIntention === 'totalWithShortage'}
                      onChange={() => setValue('paymentIntention', 'totalWithShortage')}
                    />
                    <Form.Check.Label>
                      <div className="payment-form__label_title desktop">
                        Amount with escrow shortage due {paymentDate}
                      </div>
                      <div className="payment-form__label_title tablet">
                        Amount due
                        <br />
                        {paymentDateFormat(paymentDate, true)}
                      </div>

                      <div className="payment-form__total">
                        {paymentAmountFormat(add(totalAmountDue, additionalEscrowShortage)) || '$0.00'}
                      </div>
                    </Form.Check.Label>

                    <div className="payment-form__multiple-due">
                      <p>Amount due includes additional balance:</p>
                      <ul>
                        {amountDuesForShortageDisplay.map(({ desktop, tablet }, index) => (
                          <li key={`overdue-item-${index}`}>
                            <span className="desktop">{desktop}</span>
                            <span className="tablet">{tablet}</span>
                          </li>
                        ))}
                        <li>{paymentAmountFormat(shortageAmount)} (Escrow shortage)</li>
                      </ul>
                    </div>
                  </div>
                )}
                {(isAdditionalPayment || !exactPaymentOnly) && (
                  <div
                    className={classnames(['payment-form__radio'], {
                      'u-bg-secondary': paymentIntention === 'other',
                    })}
                    onClick={() => {
                      setValue('paymentIntention', 'other');
                    }}
                    data-testid="payment-form-amount-other"
                  >
                    <Form.Check.Input
                      type="radio"
                      className="checked:u-bg-willow"
                      value="other"
                      checked={paymentIntention === 'other'}
                      onChange={() => setValue('paymentIntention', 'other')}
                    />
                    <Form.Check.Label>
                      <div className="payment-form__label_title desktop">Other Amount</div>
                      <div className="payment-form__label_title tablet">Other</div>
                      <div className="d-flex payment-form__other-input">
                        <ControlledCurrencyInput
                          id="other-amount"
                          className="payment-form__other-input"
                          control={control}
                          fieldName="otherAmount"
                          data-testid="payment-form-other-input"
                          onAmountUpdate={() => setValue('paymentIntention', 'other')} // Selects the radio button when user types
                        />
                      </div>
                    </Form.Check.Label>
                  </div>
                )}
              </div>

              <div className="payment-form__submit">
                <div className="payment-form__submit_inner">
                  <input
                    type="submit"
                    value="Submit payment"
                    data-ghost="portal-loan--form-submit-payment-button"
                    disabled={isSubmitting}
                    data-testid="payment-form-submit"
                  />
                  {isSubmitting && <Loader />}
                </div>
              </div>
            </>
          ) : (
            <button
              className="payment-details__addition__btn mt-4 u-fs-4 mb-4"
              onClick={() => setShowPaymentForm(true)}
            >
              Make a one-time payment
            </button>
          )}
        </form>
      </section>

      <ConfirmPaymentModal
        showModal={activeModal === 'confirmPayment'}
        paymentAmount={paymentAmount}
        bankName={paymentBank?.name || 'selected bank'}
        autopayEnabled={!!autopaySettings}
        onClose={() => {
          setActiveModal(undefined);
          setIsSubmitting(false);
        }}
        paymentSubmit={() => onModalSubmit()}
      />

      <UnderPaymentModal
        showModal={activeModal === 'underPayment'}
        onClose={() => {
          setActiveModal(undefined);
          setIsSubmitting(false);
        }}
        onSubmit={() => onModalSubmit()}
        minPayment={minAmountDue}
        submissionAmount={paymentAmount}
        bankName={paymentBank?.name || 'selected bank'}
        autopayEnabled={!!autopaySettings}
        disabled={isSubmitting}
      />

      <OverPaymentModal
        showModal={activeModal === 'overPayment'}
        onClose={() => {
          setActiveModal(undefined);
          setIsSubmitting(false);
        }}
        onSubmit={(additionalPayment) => onModalSubmit(additionalPayment)}
        paymentAmount={paymentAmount}
        amountDue={totalAmountDue || 0}
        autopayEnabled={!!autopaySettings}
        bankName={paymentBank?.name || 'selected bank'}
        disabled={isSubmitting}
      />

      {company && (
        <DwollaLimitModal
          company={company}
          showModal={activeModal === 'dwollaLimit'}
          onClose={() => {
            setActiveModal(undefined);
            setIsSubmitting(false);
          }}
        />
      )}
    </>
  );
};
