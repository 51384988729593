import { z } from 'zod';

import { PrincipalModificationId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanEvent } from './BaseLoanEvent';

export const ReversePrincipalModificationEvent = BaseLoanEvent.extend({
  type: z.literal('reversePrincipalModification'),
  principalModificationId: zodBrandedUuid<PrincipalModificationId>(),
});

export type ReversePrincipalModificationEvent = z.infer<typeof ReversePrincipalModificationEvent>;
