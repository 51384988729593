import { z } from 'zod';

import { BorrowerId } from '../BrandedIds';
import { PrincipalModificationInput } from '../loan/PrincipalModification';
import { zodBrandedUuid } from '../utils';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreatePrincipalModificationEvent = BaseLoanEvent.extend({
  type: z.literal('createPrincipalModification'),
  principalModification: PrincipalModificationInput,
  borrowerIds: z.array(zodBrandedUuid<BorrowerId>()),
});

export type CreatePrincipalModificationEvent = z.infer<typeof CreatePrincipalModificationEvent>;
