/*
  Payment was submitted and is currently processing.

  There is an option in this use case for making another payment.
  A user might use this option if they have underpaid
*/

import { AutopaySettings, PortalPaymentFragment } from '@willow/graphql-iso/src/portal';
import { NamedMemo } from '@willow/shared-web';
import { CompanyId, LoanId, PortalPaymentDueStatus, PortalPaymentDueSubStatus } from '@willow/types-iso';

import { Alert } from '../../alert/Alert';
import { PaymentDetails } from './payment-details/PaymentDetails';

interface Props {
  companyId: CompanyId;
  loanId: LoanId;
  processingPayments: PortalPaymentFragment[];
  status: PortalPaymentDueStatus;
  subStatus?: PortalPaymentDueSubStatus;
  autopaySettings?: AutopaySettings;
  onAdditionalPayment: () => void;
  refetch: (poll?: boolean) => void;
}

export const PaymentProcessing = NamedMemo<Props>(
  'PaymentProcessing',
  ({ companyId, loanId, processingPayments, status, subStatus, autopaySettings, onAdditionalPayment, refetch }) => {
    const autopayEnabledByServicer = Boolean(autopaySettings && autopaySettings.type === 'usio');

    return (
      <>
        {autopayEnabledByServicer ? (
          <Alert
            title="No Action Required: Withdraw Scheduled"
            subtitle="Payment will be processed in full automatically"
            level="INFO"
          />
        ) : (
          <Alert title="Payment Processing" subtitle="No amount is currently due" level="INFO" />
        )}

        <PaymentDetails
          companyId={companyId}
          loanId={loanId}
          payments={processingPayments}
          status={status}
          subStatus={subStatus}
          displayMoreButton
          onAdditionalPayment={onAdditionalPayment}
          refetch={refetch}
        />
      </>
    );
  },
);
