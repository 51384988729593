import * as z from 'zod';

import {
  CompanyId,
  CompanyReportId,
  EventId,
  InvestorReportingId,
  LoanId,
  RecurringCompanyReportId,
} from '../BrandedIds';
import { LoanInvestorName } from '../loan/Loan';
import { zodBrandedUuid, zodDateOrString } from '../utils';
import { FannieMaeLar96 } from './FannieMaeLar96';

export const InvestorReportingReportType = z.enum(['FANNIE_MAE_LAR_96']);
export type InvestorReportingReportType = z.infer<typeof InvestorReportingReportType>;

export const InvestorReportingReportTypeToInvestor: Record<InvestorReportingReportType, LoanInvestorName> = {
  FANNIE_MAE_LAR_96: 'FANNIE_MAE',
} as const;

const FannieMaeLar96ReportData = z.object({
  reportType: z.literal(InvestorReportingReportType.Enum.FANNIE_MAE_LAR_96),
  data: FannieMaeLar96,
});

export const InvestorReportingData = FannieMaeLar96ReportData;
// Make `InvestorReportingData` a discriminated union on 'reportType' when more report types are supported, eg...
// const FreddieMacLLR = z.object({
//   foo: z.string(),
// });
// const FreddieMacLLRReportData = z.object({
//   reportType: z.literal('FREDDIE_MAC_LLR'),
//   data: FreddieMacLLR,
// });
// export const InvestorReportingData = z.discriminatedUnion('reportType', [FannieMaeLar96ReportData, FreddieMacLLRReportData]);

export const InvestorReporting = z.object({
  id: zodBrandedUuid<InvestorReportingId>(),
  loanId: zodBrandedUuid<LoanId>(),
  loanSnapshotVersion: z.number(),
  companyId: zodBrandedUuid<CompanyId>(),
  eventId: zodBrandedUuid<EventId>().optional(),
  recurringCompanyReportId: zodBrandedUuid<RecurringCompanyReportId>().optional(),
  companyReportIds: zodBrandedUuid<CompanyReportId>().array(),
  investor: LoanInvestorName,
  reportType: InvestorReportingReportType,
  data: InvestorReportingData,
  createdAt: zodDateOrString,
});
export type InvestorReporting = z.infer<typeof InvestorReporting>;
