import { z } from 'zod';

import { PrincipalModificationId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseUpsertTransactionSideEffect } from './BaseUpsertTransactionSideEffect';

export const UpsertPrincipalModificationSideEffectContext = BaseUpsertTransactionSideEffect.extend({
  principalModificationId: zodBrandedUuid<PrincipalModificationId>(),
});
export const UpsertPrincipalModificationSideEffect = UpsertPrincipalModificationSideEffectContext.extend({
  type: z.literal('upsertPrincipalModification'),
});
export type UpsertPrincipalModificationSideEffect = z.infer<typeof UpsertPrincipalModificationSideEffect>;
