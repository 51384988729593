/*
    Make A Payment page acts as the portal home page

    – The view presented depends on the current payment status (see PaymentStatus prop for possible values)
    – Additionally, there may be a sub-status within the status (ex {status: 'due', subStatus: 'overdue'}. See PaymentSubStatus prop)

    There are several states that exist that will take over the normal payment view:
    1. Pre-Active
    2. On Hold
    3. Transferred loan w/ no payment due
    4. Lender payments paused
*/

import { TransferData } from '@willow/graphql-iso/src/portal';
import { NamedMemo } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../App';
import { ArchivedLoan } from '../../components/make-payment-views/ArchivedLoan';
import { MaturingLoan } from '../../components/make-payment-views/MaturingLoan';
import { PaymentsAccepted } from '../../components/make-payment-views/payments-accepted/PaymentsAccepted';
import { WhedaDemoPaymentsAccepted } from '../../components/make-payment-views/payments-accepted/WhedaDemoPaymentsAccepted';
import { LoanPaymentsPaused } from '../../components/make-payment-views/PaymentsPaused';
import { PreActive } from '../../components/make-payment-views/pre-active/PreActive';
import { TransferredLoan } from '../../components/make-payment-views/transferred-loan/TransferredLoan';
import { Metadata } from '../../components/metadata/Metadata';
import { PaidOff } from '../../components/paid-off/PaidOff';
import { AutopayContextProvider } from '../../contexts/autopayContext';
import { getPageViewType } from './pageView';

import './MakePayment.scss';

interface Props {
  loan: PortalSelectedLoan;
  refetch: (poll?: boolean) => void;
}

export const MakePaymentPage = NamedMemo<Props>('MakePaymentPage', ({ loan, refetch }) => {
  /* Determine which page view to display */
  const pageViewType = getPageViewType(loan);

  return (
    <AutopayContextProvider>
      <div className="page-make-payment" data-testid={`make-payment-${pageViewType}`}>
        <Metadata metadata={{ title: 'Make a Payment' }} />
        {pageViewType === 'preActive' && (
          <PreActive
            loanStatus={loan.currentSnapshot.loanStatus}
            nextStatementDate={loan.currentSnapshot.nextStatementDate}
          />
        )}
        {pageViewType === 'transferred' && (
          <TransferredLoan
            company={loan.company}
            transferData={loan.currentSnapshot.transferData as TransferData}
            isPending={loan.currentSnapshot.loanStatus === 'pendingTransfer'}
          />
        )}
        {pageViewType === 'paidOff' && <PaidOff loan={loan} />}
        {pageViewType === 'archived' && <ArchivedLoan company={loan.company} />}
        {pageViewType === 'maturing' && <MaturingLoan loan={loan} />}
        {pageViewType === 'pausedPayments' && <LoanPaymentsPaused company={loan.company} />}
        {pageViewType === 'payment' && (
          <>
            {loan.company.adminSettings.whedaEnabled ? (
              <WhedaDemoPaymentsAccepted company={loan.company} loan={loan} refetch={refetch} />
            ) : (
              <PaymentsAccepted company={loan.company} loan={loan} refetch={refetch} />
            )}
          </>
        )}
      </div>
    </AutopayContextProvider>
  );
});
