import {
  DocumentType,
  EscrowAnalysisDocument,
  Form1098Document,
  GeneralDocument,
  PayoffDocument,
  PortalDocumentFragment,
  PortalPaymentDueFragment,
  UploadDocument,
} from '@willow/graphql-iso/src/portal';
import { EMAIL_TITLES } from '@willow/shared-iso';
import {
  assertNever,
  dateFormat,
  DocumentDisplayItem,
  getStatementItems,
  paymentAmountFormat,
  RevisedCallout,
  sortDocumentsByDate,
} from '@willow/shared-web';

import { TDocumentTypeOption } from '../../../pages/documents/form';

export function getTableItems(
  documentType: TDocumentTypeOption,
  paymentsDue: PortalPaymentDueFragment[],
  documents: PortalDocumentFragment[],
): DocumentDisplayItem[] {
  const formattedStatements = getStatementItems(paymentsDue);
  const formattedDocuments = getDocumentItems(documents);

  // Filter by document type
  const filteredStatements = formattedStatements.filter((s) => {
    const archivedStatement = s.callouts.includes(RevisedCallout);
    return documentType === 'current' ? !archivedStatement : archivedStatement;
  });
  const filteredDocuments = formattedDocuments.filter((d) => {
    const outdatedDocument = d.callouts.includes('Expired');
    return documentType === 'current' ? !outdatedDocument : outdatedDocument;
  });

  // Combine + sort the items
  const combinedDocuments = [...filteredStatements, ...filteredDocuments];
  const sortedDocuments = sortDocumentsByDate(combinedDocuments);

  return sortedDocuments.map((document) => {
    return {
      ...document,
      date: dateFormat(document.date.toString()),
    };
  });
}

function getDocumentItems(documents: PortalDocumentFragment[]): DocumentDisplayItem[] {
  const formattedDocuments = documents?.map((document) => {
    const { type, id, createdAt, version } = document;

    let title;
    let subtitle: string | undefined;
    let callouts: string[] = [];
    let amount: string | undefined;
    let sortByDate: Date = createdAt;
    let notificationType: DocumentDisplayItem['notificationType'] = 'document';

    version > 1 && callouts.push('Revised');

    switch (type) {
      case DocumentType.MonthlyStatement:
        // Note: This is not in use at this point
        title = 'Monthly Statement';
        break;
      case DocumentType.Goodbye:
        title = 'Goodbye Letter';
        break;
      case DocumentType.Payoff:
        title = `Payoff Statement`;
        const { isExpired: payoffIsExpired, payoffAmount } = document as PayoffDocument;
        payoffIsExpired && callouts.push('Expired');
        amount = paymentAmountFormat(payoffAmount);
        break;
      case DocumentType.PaidInFull:
        const { amount: paidAmount } = document as GeneralDocument;
        title = 'Paid in Full Statement';
        amount = paymentAmountFormat(paidAmount || 0);
        break;
      case DocumentType.PartialPayoffConfirmation:
        title = 'Partial Payoff Confirmation';
        break;
      case DocumentType.LenderUpload:
      case DocumentType.BorrowerUpload:
        title = (document as UploadDocument).title;
        notificationType = 'manuallyUploaded';
        break;
      case DocumentType.Form1098: {
        const { isCorrected, year } = document as Form1098Document;
        title = `${isCorrected ? 'CORRECTED: ' : ''}${year} Form 1098`;
        break;
      }
      case DocumentType.EscrowAnalysis: {
        const { isCorrected, effectiveDate } = document as EscrowAnalysisDocument;
        title = `${isCorrected ? 'CORRECTED: ' : ''}Escrow Analysis (effective ${dateFormat(
          effectiveDate,
          'LLL yyyy',
        )})`;
        break;
      }
      case DocumentType.DefermentWorkoutPlan:
        title = 'Deferment Workout Agreement';
        break;
      case DocumentType.RepaymentWorkoutPlan:
        title = 'Repayment Workout Agreement';
        break;
      case DocumentType.ForbearanceWorkoutPlan:
        title = 'Forbearance Workout Agreement';
        break;
      case DocumentType.DelinquentInterestApplied:
        title = 'Default Interest Applied';
        break;
      case DocumentType.PrincipalModificationApplied:
        title = 'Principal Modification Applied';
        break;
      case DocumentType.PreActiveWelcome:
        title = EMAIL_TITLES.welcome;
        break;
      default:
        assertNever(type);
    }

    return {
      id,
      documentType: 'Document' as const,
      documentSpecificationType: type,
      notificationType,
      title,
      subtitle,
      callouts,
      sortByDate,
      date: createdAt,
      amount,
      version,
    };
  });

  return formattedDocuments;
}
