import { startCase } from 'lodash';
import * as z from 'zod';

import { IndexRateType } from '../../indexRate';
import { ArmRoundingType, BuydownType, LoanInvestorName } from '../../loan/Loan';
import { LoanStatusActive } from '../../loan/LoanStatus';
import { OptionalPhoneNumber } from '../../Phone';
import { OptionalEmailString } from '../email';
import {
  AddressLine,
  Checkbox,
  dateIsTodayOrInThePast,
  dateIsWithinRange,
  DateString,
  InterestRate,
  isValidPaymentDate,
  MonetaryValue,
  PersonNameString,
  PositiveFloat,
  PositiveInteger,
  PositiveMonetaryValue,
  PositivePercentage,
  SocialSecurityNumber,
  StateAbbreviation,
  Zipcode,
} from '../fields';
import { CreateLoanAddressFields, CreateLoanFields, CreateLoanMultipleAddressFields } from './createLoanFields';

// Reference these inputs in client, not preprocessed types below
export const AmortizationTypeInput = z.enum(['arm', 'fixed']);
export const LoanTypeInput = z.enum(['arm', 'fixed', 'heloc']);
export const EscrowWaivedInput = z.enum(['Waived', 'Not Waived']);
export const InterestTypeInput = z.enum(['30-360', 'actual-365', 'actual-360']);
export const ProductTypeInput = z.enum([
  'fha',
  'va',
  'usda',
  'pih',
  'conv',
  'heloc',
  'bridge', // FOR VONTIVE
  'businessPurpose', // FOR VONTIVE
  'jumbo',
]);
export const LoanPurposeInput = z.enum(['purchase', 'refinance']);
export const OccupancyTypeInput = z.enum(['primary', 'second', 'investment']);
export const LoanStatusInput = z.enum([LoanStatusActive.value]);

// These are only for use in this file
export const LoanTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, LoanTypeInput);
export const AmortizationTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, AmortizationTypeInput);
export const EscrowWaivedEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return startCase(val.toLowerCase());
  }
  return val;
}, EscrowWaivedInput);
export const InterestTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, InterestTypeInput);

export const ProductTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    if (val.toLowerCase() === 'businesspurpose') {
      return 'businessPurpose';
    }
    return val.toLowerCase();
  }
  return val;
}, ProductTypeInput);

export const LoanPurposeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, LoanPurposeInput);

export const OccupancyTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, OccupancyTypeInput);

export const ConstructionToPermanentStatusInput = z.enum(['active', 'permanent']);

export const ConstructionToPermanentStatusEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, ConstructionToPermanentStatusInput);

export const ConstructionLoanTypeInput = z.enum(['constructionOnly', 'constructionToPermanent']);

export const ConstructionLoanTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    if (val.toLowerCase() === 'constructiononly') {
      return 'constructionOnly';
    }
    if (val.toLowerCase() === 'constructiontopermanent') {
      return 'constructionToPermanent';
    }
  }
  return val;
}, ConstructionLoanTypeInput);

export const InvestorNameEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    val = val.toLowerCase().replace(/[_ ]/g, '');
  }
  switch (val) {
    case 'fanniemae':
      return LoanInvestorName.enum.FANNIE_MAE;
    case 'freddiemac':
      return LoanInvestorName.enum.FREDDIE_MAC;
    default:
      return val;
  }
}, LoanInvestorName);

export const NoteholderInput = z.enum(['investor', 'servicer', 'thirdParty']);
export const NoteholderEnum = z.preprocess((val) => {
  if (typeof val === 'number') {
    val = `${val}`;
  }
  if (typeof val === 'string') {
    val = val.toLowerCase();
  }

  switch (val) {
    case '0':
      return 'investor';
    case '1':
      return 'servicer';
    case '2':
    case 'thirdparty':
      return 'thirdParty';
    default:
      return val;
  }
}, NoteholderInput);

// NOTE: If parsing data with CreateLoanRow, make sure
// you trim and strip the data beforehand with the `trimAndStrip` util
export const CreateLoanRow = z
  .object({
    loanId: z.string(),
    poolId: z.string().optional(),
    previousServicerId: z.string().optional(),
    capitalPartnerId: z.string().optional(),
    fullId: z.string().optional(),
    otherId1: z.string().optional(),
    otherId2: z.string().optional(),
    otherId3: z.string().optional(),
    ownerId: z.string().optional(),

    // Borrower fields
    primaryBorrowerFirstName: PersonNameString,
    primaryBorrowerMiddleName: PersonNameString.optional(),
    primaryBorrowerLastName: PersonNameString,
    primaryBorrowerPhone: OptionalPhoneNumber,
    primaryBorrowerEmail: OptionalEmailString,
    primaryBorrowerEmailConsent: Checkbox.optional(),
    primaryBorrowerMailingAddressLine1: AddressLine.optional(),
    primaryBorrowerMailingAddressLine2: AddressLine.optional(),
    primaryBorrowerMailingAddressLine3: AddressLine.optional(),
    primaryBorrowerMailingAddressLine4: AddressLine.optional(),
    primaryBorrowerMailingAddressLocality: z.string().optional(),
    primaryBorrowerMailingAddressRegion: StateAbbreviation.optional().or(z.literal('')),
    primaryBorrowerMailingAddressPostcode: Zipcode.optional(),
    primaryBorrowerMailingAddressCountry: z.string().optional(),
    primaryBorrowerSSN: SocialSecurityNumber.optional(),

    fundingDate: DateString.and(dateIsTodayOrInThePast),
    isAcquired: Checkbox.optional(),
    acquisitionDate: DateString.optional(),
    correspondentLender: z.string().optional(),
    correspondentLenderPhone: OptionalPhoneNumber,
    loanMaturityDate: DateString.and(isValidPaymentDate),
    loanClosingDate: DateString.optional(),
    loanTermMonths: PositiveInteger.optional(),
    loanTermYears: PositiveInteger.optional(),
    loanType: LoanTypeEnum.optional(),
    amortizationType: AmortizationTypeEnum.optional(),
    productType: ProductTypeEnum.optional(),
    productDescription: z.string().optional(),
    loanPurpose: LoanPurposeEnum.optional(),
    constructionToPermanentStatus: ConstructionToPermanentStatusEnum.optional(),
    constructionLoanType: ConstructionLoanTypeEnum.optional(),
    occupancyType: OccupancyTypeEnum.optional(),
    initialDiscountPointsAmount: PositiveMonetaryValue.optional(),
    legalDescription: z.string().optional(),
    numberOfProperties: PositiveInteger.optional(),
    loanPrincipal: PositiveMonetaryValue,
    loanInterestRateDecimal: InterestRate.optional(),
    loanInterestRatePercent: PositivePercentage.optional(),
    firstCollectedPaymentDate: DateString.and(isValidPaymentDate)
      .and(dateIsWithinRange({ years: 30 }))
      .optional(),
    minMonthlyPayment: PositiveMonetaryValue.optional(),
    currentOutstandingAmount: PositiveMonetaryValue.optional(),
    gracePeriod: PositiveInteger.optional(),
    defaultInterestRateDecimal: PositiveFloat.optional(),
    defaultInterestRatePercent: PositivePercentage.optional(),
    defaultInterestEffectiveDate: DateString.optional(),

    // investor info
    investorName: InvestorNameEnum.optional(),
    investorLoanId: z.string().optional(),
    investorMasterId: z.string().optional(),
    ginnieMaeLoanId: z.string().optional(),
    fhaCaseNumber: z.string().optional(),
    mortgageIdentificationNumber: z.string().optional(),
    mersRegistrationDate: DateString.optional(),
    noteholder: NoteholderEnum.optional(),
    documentCustodian: z.string().optional(),

    // stop settings
    stopEmails: Checkbox.optional(),
    stopPayments: Checkbox.optional(),
    exactPaymentsOnly: Checkbox.optional(),

    // arm fields
    armIndex: IndexRateType.optional(),
    armFixedTermMonths: PositiveInteger.optional(),
    armFirstRateChangeDate: DateString.optional(),
    armMarginPercent: PositivePercentage.optional(),
    armMarginDecimal: PositiveFloat.optional(),
    armMaxIncreasePerPeriodPercent: PositivePercentage.optional(),
    armMaxIncreasePerPeriodDecimal: PositiveFloat.optional(),
    armMinIncreasePerPeriodPercent: PositivePercentage.optional(),
    armMinIncreasePerPeriodDecimal: PositiveFloat.optional(),
    armMaxIncreaseForFirstPeriodPercent: PositivePercentage.optional(),
    armMaxIncreaseForFirstPeriodDecimal: PositiveFloat.optional(),
    armMinIncreaseForFirstPeriodPercent: PositivePercentage.optional(),
    armMinIncreaseForFirstPeriodDecimal: PositiveFloat.optional(),
    armRateCeilingPercent: PositivePercentage.optional(),
    armRateCeilingDecimal: PositiveFloat.optional(),
    armRateFloorPercent: PositivePercentage.optional(),
    armRateFloorDecimal: PositiveFloat.optional(),
    armRateAdjustmentFrequency: PositiveInteger.optional(),
    armAdjustmentLeadCountDays: PositiveInteger.optional(),
    armRoundingType: ArmRoundingType.optional(),
    armRoundingPercent: PositivePercentage.optional(),
    armRoundingDecimal: PositiveFloat.optional(),

    // interest only fields
    interestOnlyTermMonths: PositiveInteger.optional(),
    interestOnlyTermYears: PositiveInteger.optional(),
    interestOnlyFlag: Checkbox.optional(),

    // THIS IS AN OLD TODO: make `interestType` required but first need to reach out to loansnap
    interestType: InterestTypeEnum.optional(),
    firstPaymentPrincipal: PositiveMonetaryValue.optional(),
    firstPaymentInterest: PositiveMonetaryValue.optional(),
    firstPaymentPrincipalAndInterest: PositiveMonetaryValue.optional(),
    firstPaymentTotal: PositiveMonetaryValue.optional(),
    isEscrowed: Checkbox.optional(),
    escrowWaived: EscrowWaivedEnum.optional(),
    monthlyPaymentEscrow: PositiveMonetaryValue.optional(),
    firstPaymentDate: DateString.and(isValidPaymentDate).and(dateIsWithinRange({ years: 30 })),
    initialEscrowBalance: MonetaryValue.optional(),
    initialInterestBalance: MonetaryValue.optional(),
    initialPrincipalPrepaymentBalance: PositiveMonetaryValue.optional(),
    initialInterestReserveBalance: PositiveMonetaryValue.optional(), // BUYDOWN TODO: Remove when finally deprecated
    initialMortgageInsuranceAmount: PositiveMonetaryValue.optional(),
    initialReserveBalance: PositiveMonetaryValue.optional(),
    buydownType: BuydownType.optional(),
    reserveBalanceFundedByThirdParty: Checkbox.optional(),
    isInterestReserve: Checkbox.optional(),
    isPendingActivation: Checkbox.optional(),
    isDutch: Checkbox.optional(),

    // Draw fields
    drawsEnabled: Checkbox.optional(),
    maxLineOfCredit: PositiveMonetaryValue.optional(),
    drawTerm: PositiveInteger.optional(),
    drawExpirationDate: DateString.optional(),
    repayBeginDate: DateString.optional(),
    draw1Date: DateString.optional(),
    draw1Amount: PositiveMonetaryValue.optional(),
    draw2Date: DateString.optional(),
    draw2Amount: PositiveMonetaryValue.optional(),
    draw3Date: DateString.optional(),
    draw3Amount: PositiveMonetaryValue.optional(),
    collectDrawInterestBeforeFirstMonth: Checkbox.optional(),

    // Entity fields
    entityCompanyId: z.string().optional(),
    entityName: z.string().optional(),
    entityEin: z.string().optional(),
    entityPhone: OptionalPhoneNumber,
    entityEmail: OptionalEmailString,
    entityAddressLine1: AddressLine.optional(),
    entityAddressLine2: AddressLine.optional(),
    entityAddressLocality: z.string().optional(),
    entityAddressRegion: StateAbbreviation.optional().or(z.literal('')),
    entityAddressPostcode: Zipcode.optional(),
    entityAddressCountry: z.string().optional(),

    // escrow fields
    escrow1Type: z.string().optional(),
    escrow1AgentName: z.string().optional(),
    escrow1AgentEmail: OptionalEmailString,
    escrow1AgentPhone: OptionalPhoneNumber,
    escrow1CompanyName: z.string().optional(),
    escrow1CompanyAddressLine1: AddressLine.optional(),
    escrow1CompanyAddressLine2: AddressLine.optional(),
    escrow1CompanyAddressCity: z.string().optional(),
    escrow1CompanyAddressState: StateAbbreviation.optional().or(z.literal('')),
    escrow1CompanyAddressZip: Zipcode.optional(),
    escrow1PolicyNumber: z.string().optional(),
    escrow1PolicyStart: DateString.optional(),
    escrow1PolicyEnd: DateString.optional(),
    escrow2Type: z.string().optional(),
    escrow2AgentName: z.string().optional(),
    escrow2AgentEmail: OptionalEmailString,
    escrow2AgentPhone: OptionalPhoneNumber,
    escrow2CompanyName: z.string().optional(),
    escrow2CompanyAddressLine1: AddressLine.optional(),
    escrow2CompanyAddressLine2: AddressLine.optional(),
    escrow2CompanyAddressCity: z.string().optional(),
    escrow2CompanyAddressState: StateAbbreviation.optional().or(z.literal('')),
    escrow2CompanyAddressZip: Zipcode.optional(),
    escrow2PolicyNumber: z.string().optional(),
    escrow2PolicyStart: DateString.optional(),
    escrow2PolicyEnd: DateString.optional(),
    escrow3Type: z.string().optional(),
    escrow3AgentName: z.string().optional(),
    escrow3AgentEmail: OptionalEmailString,
    escrow3AgentPhone: OptionalPhoneNumber,
    escrow3CompanyName: z.string().optional(),
    escrow3CompanyAddressLine1: AddressLine.optional(),
    escrow3CompanyAddressLine2: AddressLine.optional(),
    escrow3CompanyAddressCity: z.string().optional(),
    escrow3CompanyAddressState: StateAbbreviation.optional().or(z.literal('')),
    escrow3CompanyAddressZip: Zipcode.optional(),
    escrow3PolicyNumber: z.string().optional(),
    escrow3PolicyStart: DateString.optional(),
    escrow3PolicyEnd: DateString.optional(),
    monthlyPaymentMi: PositiveMonetaryValue.optional(), // TODO deprecate this from FF and the API
    beginningOfYearPrincipalBalance: PositiveMonetaryValue.optional(),

    // fee fields
    lateFeePercent: PositivePercentage.optional(),
    lateFeeDecimal: PositiveFloat.optional(),
  })
  // Previously we did a bunch of extra merges here that resulted in the following
  // TS error:
  //
  // Recursive type: Type instantiation is excessively deep and possibly infinite.
  //
  // I suspect doing the merge in another file somehow allowed TS to better divy up the work
  // it needed to do to construct the dervice type
  .merge(CreateLoanFields)
  .merge(CreateLoanAddressFields)
  .merge(CreateLoanMultipleAddressFields)
  .strict();
export type CreateLoanRow = z.infer<typeof CreateLoanRow>;

export const CREATE_LOAN_ROW_REQUIRED_FIELDS = Object.keys(CreateLoanRow.shape).filter(
  (key) => !CreateLoanRow.shape[key as keyof typeof CreateLoanRow.shape].isOptional(),
);
