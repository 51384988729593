import { z } from 'zod';

import { PayoffId } from '../BrandedIds';
import { ShortageActionType, TrackedPayoffAllowedPaymentMethods } from '../loan/Payoffs';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { PositiveFloat } from '../validations/fields';
import { BaseLoanEvent } from './BaseLoanEvent';

export const RecordPayoffEvent = BaseLoanEvent.extend({
  type: z.literal('recordPayoff'),
  shortageAction: ShortageActionType.optional(),
  dateReceived: zodDateOrString, // This is the date selected by lender while recording the payoff
  paymentMethod: TrackedPayoffAllowedPaymentMethods,
  referenceId: z.string().optional(),
  submittedBy: z.string().optional(),
  amountReceived: PositiveFloat,
  amountExpected: PositiveFloat,
  sendEmail: z.boolean(),
  payoffId: zodBrandedUuid<PayoffId>().optional(), // TODO make this required after migration
  isPartial: z.boolean().optional(),
  additionalPrincipal: PositiveFloat.optional(),
  additionalEscrow: PositiveFloat.optional(),
  additionalSuspense: PositiveFloat.optional(),
});

export type RecordPayoffEvent = z.infer<typeof RecordPayoffEvent>;
