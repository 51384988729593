import { mapValues } from 'lodash';
import * as z from 'zod';

export const trimAndStrip = (val: any): any | undefined => {
  // Trim strings
  if (typeof val === 'string') {
    val = val.trim();
    val = transformNullStringToNull(val);
  }

  // Turn "empty" values into undefined
  if (val === null || val === '') {
    return undefined;
  }

  if (Array.isArray(val)) {
    return val.map(trimAndStrip);
  }

  if (typeof val === 'object' && val !== null) {
    return mapValues(val, trimAndStrip);
  }

  return val;
};

const transformNullStringToNull = (val: string) => (val.toLowerCase() === 'null' ? null : val);

// This is kind of funky and is really only useful for how we handle
// cross field parsers. The idea is that we attempt to apply refinements
// even when the initial parsing fails. To do that we have to bypass
// initial validation. Otherwise the following would not run
// const schema = z.object({ name: z.string().min(3) })
//                 .superRefine((data, ctx) => { console.log("RUNS!") })
// schema.parse({ name: 'Mark' }); // You will see "RUNS!"
// schema.parse({ name: 'M' }); // You will see "RUNS!" (even though string length fails)
// schema.parse({}); // You will NOT see "RUNS!" (because `name` was required and is missing)
export function unsafeCreateCastedSchema<T extends z.ZodTypeAny>(_schema: T): T {
  return z.any() as unknown as T;
}
