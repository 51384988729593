import * as z from 'zod';

import { zodDateOrString } from '../utils';

export const FannieMaeLar96V1 = z.object({
  version: z.literal('1'),
  lenderNumber: z.string(),
  investor: z.literal('F'),
  recordIdentifier: z.literal('96'),
  sourceCode: z.literal('0'),
  fannieMaeLoanNumber: z.string(),
  lpiDate: zodDateOrString,
  unpaidPrincipalBalance: z.number(),
  interest: z.number(),
  principal: z.number(),
  actionCode: z.enum(['00', '60', '65', '67', '70', '71', '72']),
  actionDate: zodDateOrString,
  otherFees: z.number(),
  filler: z.literal('0000'),
});
export type FannieMaeLar96V1 = z.infer<typeof FannieMaeLar96V1>;

export const FannieMaeLar96 = FannieMaeLar96V1;
export type FannieMaeLar96 = z.infer<typeof FannieMaeLar96>;
// Make `FannieMaeLar96Report` a discriminated union on 'version' when changes happen
// export const FannieMaeLar96V2 = z.object({
//   version: z.literal('2'),
//   foo: z.string(),
// });
// export const FannieMaeLar96 = z.discriminatedUnion('version', [FannieMaeLar96V1, FannieMaeLar96V02012025]);
