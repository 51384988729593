import { CompanyFragment, PortalPropertyFragment } from '@willow/graphql-iso/src/portal';
import { addressToString, isNotNull, phoneNumberFormat, stripObjectNullFromNullOrUndefined } from '@willow/shared-iso';
import {
  decimalToPercentage,
  formatThirdPartyContactType,
  paymentAmountFormat,
  paymentDateFormat,
} from '@willow/shared-web';

import { PortalSelectedLoan } from '../../App';
import { Metadata } from '../../components/metadata/Metadata';
import { PageHeading } from '../../components/page-heading/PageHeading';

import './LoanOverview.scss';

interface Props {
  loan: PortalSelectedLoan;
  company: CompanyFragment;
}

export const LoanOverviewPage = ({ loan, company }: Props) => {
  const {
    primaryBorrower,
    additionalBorrowers,
    authorizedContacts,
    configuration,
    originalAmount,
    currentSnapshot,
    entity,
  } = loan;

  const { interestRate, escrowed, buydownType } = configuration || {};
  const {
    creditRemaining,
    maturityConfig: { maturityDate },
    properties,
    currentPaymentConfig: { monthlyInterestRate },
    drawsEnabled,
    maxLineOfCredit,
    interestRateChangeHistory,
  } = currentSnapshot || {};
  const { outstandingBalance, balances } = currentSnapshot?.snapshot || {};

  const primaryBorrowerName = entity ? entity.name : `${primaryBorrower.firstName} ${primaryBorrower.lastName}`;
  const additionalBorrowerList = entity ? [primaryBorrower, ...additionalBorrowers] : additionalBorrowers;
  const additionalBorrowerDescription = entity ? 'Authorized Contact' : 'Co-Borrower';

  const activeProperties = properties.filter((property) => !property.isReleased);
  const releasedProperties = properties.filter((property) => property.isReleased);

  return (
    <div className="page-loan-overview">
      <Metadata metadata={{ title: 'Loan Overview' }} />

      <PageHeading title="Loan Overview" />

      <section className="page-loan-overview__container">
        <h2 className="page-loan-overview__list-title">Borrower Information</h2>
        <ul className="page-loan-overview__list">
          <li>
            <div className="page-loan-overview__list-item">{primaryBorrowerName}</div>
            <div className="page-loan-overview__list-item-title">Primary Borrower</div>
          </li>
          {additionalBorrowerList.map((borrower, index) => {
            const { firstName, lastName } = borrower;
            return (
              <li key={`loan-overview-borrower-${index}`}>
                <div className="page-loan-overview__list-item">
                  {firstName} {lastName}
                </div>
                <div className="page-loan-overview__list-item-title">{additionalBorrowerDescription}</div>
              </li>
            );
          })}
        </ul>

        {authorizedContacts.length > 0 ? (
          <>
            <h2 className="page-loan-overview__list-title">Authorized Contacts</h2>
            <div className="mt-2 mb-3 u-color-bark3 u-fs-2">
              The following companies and contacts are permitted to inquire about your loan but do not have access to
              your loan portal. To remove a contact, please call{' '}
              <a href={`tel:${company.supportPhone}`} aria-label={`Phone Number: ${company.supportPhone}`}>
                {phoneNumberFormat(company.supportPhone)}
              </a>
              .
            </div>
            <ul className="page-loan-overview__list">
              {authorizedContacts.map((contact, index) => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { __typename, type } = contact;
                return (
                  <li key={`loan-overview-contact-${index}`}>
                    <div className="page-loan-overview__list-item">
                      {__typename === 'BuilderAuthorizedContact' ? (
                        <>{contact.builderCompanyName}</>
                      ) : (
                        <>
                          {contact.thirdPartyCompanyName
                            ? contact.thirdPartyCompanyName
                            : `${contact.firstName} ${contact.lastName}`}
                        </>
                      )}
                    </div>
                    <div className="page-loan-overview__list-item-title">{formatThirdPartyContactType(type)}</div>
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <></>
        )}

        <h2 className="page-loan-overview__list-title">Loan Details</h2>
        <ul className="page-loan-overview__list">
          {drawsEnabled ? (
            <>
              <li>
                <div className="page-loan-overview__list-item">{paymentAmountFormat(maxLineOfCredit)}</div>
                <div className="page-loan-overview__list-item-title">Total Loan Amount</div>
              </li>
              <li>
                <div className="page-loan-overview__list-item">{paymentAmountFormat(outstandingBalance)}</div>
                <div className="page-loan-overview__list-item-title">Credit In Use</div>
              </li>
              <li>
                <div className="page-loan-overview__list-item">{paymentAmountFormat(creditRemaining)}</div>
                <div className="page-loan-overview__list-item-title">Remaining Credit</div>
              </li>
            </>
          ) : (
            <>
              <li>
                <div className="page-loan-overview__list-item">{paymentAmountFormat(originalAmount)}</div>
                <div className="page-loan-overview__list-item-title">Loan Amount</div>
              </li>
              <li>
                <div className="page-loan-overview__list-item">{paymentAmountFormat(outstandingBalance)}</div>
                <div className="page-loan-overview__list-item-title">Outstanding Principal</div>
              </li>
            </>
          )}
          {escrowed && (
            <li>
              <div className="page-loan-overview__list-item">{paymentAmountFormat(balances.escrowBalance || 0)}</div>
              <div className="page-loan-overview__list-item-title">Escrow Balance</div>
            </li>
          )}
          {balances.holdbackBalance != null && balances.holdbackBalance > 0 && (
            <li>
              <div className="page-loan-overview__list-item">{paymentAmountFormat(balances.holdbackBalance || 0)}</div>
              <div className="page-loan-overview__list-item-title">Holdback Balance</div>
            </li>
          )}
          {isNotNull(balances.reserveBalance) && (
            <li>
              <div className="page-loan-overview__list-item">{paymentAmountFormat(balances.reserveBalance)}</div>
              <div className="page-loan-overview__list-item-title">Reserve Balance</div>
            </li>
          )}
          <li>
            <div className="page-loan-overview__list-item">{decimalToPercentage(interestRate, 3)}</div>
            <div className="page-loan-overview__list-item-title">Note Rate</div>
          </li>
          {buydownType && (
            <li>
              <div className="page-loan-overview__list-item">{decimalToPercentage(monthlyInterestRate, 3)}</div>
              <div className="page-loan-overview__list-item-title">Buydown Rate</div>
            </li>
          )}
          <li>
            <div className="page-loan-overview__list-item">{paymentDateFormat(maturityDate, true)}</div>
            <div className="page-loan-overview__list-item-title">Maturity Date</div>
          </li>
        </ul>

        {interestRateChangeHistory.length > 0 && (
          <>
            <h2 className="page-loan-overview__list-title">Interest Rate Change History</h2>
            <ul className="page-loan-overview__list">
              {interestRateChangeHistory.map((item, index) => (
                <li key={index}>
                  <div className="page-loan-overview__list-item">
                    {decimalToPercentage(item.monthlyInterestRate + (item.delinquentInterestRate ?? 0), 3)}
                  </div>
                  <div className="page-loan-overview__list-item-title">{item.effectiveDate}</div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeProperties.length > 0 && <PropertiesList properties={activeProperties} title="Property Details" />}
        {releasedProperties.length > 0 && (
          <PropertiesList properties={releasedProperties} title="Released Properties (paid off)" />
        )}
      </section>
    </div>
  );
};

const PropertiesList = ({ properties, title }: { properties: PortalPropertyFragment[]; title: string }) => (
  <>
    <h2 className="page-loan-overview__list-title">{title}</h2>
    <ul className="page-loan-overview__list">
      {properties.map((property) => (
        <li key={property.id}>
          <div className="page-loan-overview__list-item">
            {addressToString(stripObjectNullFromNullOrUndefined(property.address))}
          </div>
        </li>
      ))}
    </ul>
  </>
);
