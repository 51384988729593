import { z } from 'zod';

import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateNoteflowDocumentIdEvent = BaseLoanEvent.extend({
  type: z.literal('updateNoteflowDocumentId'),
  documentId: z.string(),
  noteflowDocumentId: z.string(),
});

export type UpdateNoteflowDocumentIdEvent = z.infer<typeof UpdateNoteflowDocumentIdEvent>;
